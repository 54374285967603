import React, { Component } from "react"
// import { Link } from "gatsby"
import { navigate } from "@reach/router" 
import PropTypes from "prop-types"
import { inject, observer } from "mobx-react"
import { Grid } from "@material-ui/core"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Section from "../../components/Section"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Badge from "../../components/Badge"
import Paper from "@material-ui/core/Paper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { numberWithCommas } from "../../utils/common"
import {
  faMapMarkerAlt,
  faUserFriends,
  faClock,
  faAddressCard,
  faCreditCard
} from "@fortawesome/free-solid-svg-icons"
import Countdown, { zeroPad } from "react-countdown"
import TextField from "@material-ui/core/TextField"
import moment from "moment"
import "moment/locale/th"
moment.locale("th")

class BookingDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bid: "",
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      checkIn: "",
      checkOut: "",
      adults: 2,
      adultsKid: 0,
      price: 0,
      totalPrice: 0,
      vatPrice: 0,
      vatRate: 0,
      house: {},
      payments: [],
      createDate: "",
      bankAccount: {},
      status: "pending"
    }
  }

  renderCountdown = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <span style={{ color: "red" }}>&nbsp;หมดเวลาชำระเงินแล้ว</span>
      )
    } else {
      // Render a countdown
      return (
        <span style={{ color: "red" }}>
          &nbsp;เหลือเวลาชำระเงินอีก&nbsp;{zeroPad(hours)}:
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      )
    }
  }
  // UNSAFE_componentWillMount = async () => {
  componentDidMount = async () => {
    let params = new URLSearchParams(this.props.location.search)
    const bid = params.get("bid")
    if (bid) {
      const bankAccount = {
        account_name: "xxxx xxxx",
        account_no: "000-0-000-0",
        bank_name: "-",
        branch: "-",
        id: 0,
        status: "ACTIVE",
      }
      const data = await this.props.booking.getBookingBID(bid)
      if(data.status === "paid" || data.status === "pending"){
        this.setState(prevState => {
          prevState.bid = bid
          prevState.firstname = data.firstname
          prevState.lastname = data.lastname
          prevState.email = data.email
          prevState.mobile = data.mobile
          prevState.checkIn = data.start_date
          prevState.checkOut = data.end_date
          prevState.adults = data.adults
          prevState.adultsKid = data.adults_kid
          prevState.price = data.price
          prevState.totalPrice = data.total_price
          prevState.vatPrice = data.vat_price
          prevState.vatRate = data.vat_rate
          prevState.house = data.house
          prevState.payments = data.payments
          prevState.createDate = data.created_at
          prevState.bankAccount = data.bank_account || bankAccount
          prevState.status = data.status
          return prevState
        })
      }else{
        navigate(`/`)
      }

    } else {
      navigate(`/404`)
    }
  }

  render() {
    const imgShare = `${process.env.AWS_S3_URL}/assets/share-social-new.jpg`
    const { classes } = this.props
    const house = this.state.house
    const checkIn = moment(this.state.checkIn, "YYYY-MM-DD")
    const checkOut = moment(this.state.checkOut, "YYYY-MM-DD")
    let timeCountdown = Date.now()

    if (this.state.createDate) {
      const tomorrow = moment(this.state.createDate)
        .add(1, "days")
        .format("YYYY-MM-DD")
      timeCountdown = moment(`${tomorrow}T12:00:00`)
        .toDate()
        .getTime()
    }
    let houseImageHighLight = house.house_images ? house.house_images.find(f => f.is_highlight) : null
    if(house && house.house_images){
      if (!houseImageHighLight && house.house_images.length > 0) {
        houseImageHighLight = house.house_images.find(e => !!e) || { url: `${process.env.AWS_S3_URL}/assets/img-null.png` };
      }
    }

    let paymentTotal = 0
    if (this.state.payments && this.state.payments.length) {
      paymentTotal = this.state.payments
        .filter(val => val.status === "paid")
        .reduce(function (prev, cur) {
          return prev + +cur.amount
        }, 0)
    }

    return (
      <Layout page="booking">
        <div className={classes.bookingContainer}>
        <SEO
          title="BEST HAVEN POOLVILLA"
          description="แหล่งรวมบ้านพักพูลวิลล่าพร้อมสระว่ายน้ำส่วนตัว
    และบ้านพักตากอากาศที่มากที่สุดและราคาดีที่สุดในโซนเขาใหญ่
    และบริเวณใกล้เคียงโดยสามารถจองผ่านทางเราโดยตรงได้ทันที"
          image={imgShare}
        />
          <Section>{"รายละเอียดการจอง"}</Section>
          <div className={classes.spaceBox}></div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <Paper>
                <div className={classes.bookingForm}>
                  <h3>
                    <FontAwesomeIcon icon={faAddressCard} /> หมายเลขการจอง :{" "}
                    <span style={{ color: 'blue'}}>{this.state.bid}</span>
                  </h3>
                  { this.state.status !== "paid" &&
                  <h3>
                  <FontAwesomeIcon icon={faClock} color="red"/>
                  <Countdown
                      date={timeCountdown}
                      renderer={this.renderCountdown}
                    />
                  </h3>
                  }
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>ชื่อ</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={this.state.firstname}
                          disabled
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>สกุล</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={this.state.lastname}
                          disabled
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>อีเมล</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={this.state.email}
                          disabled
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>หมายเลขโทรศัพท์</h4>
                        <TextField
                          type="number"
                          variant="outlined"
                          fullWidth
                          value={this.state.mobile}
                          disabled
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
              <Paper style={{ marginTop: "20px" }}>
                <div className={classes.bookingForm}>
                  <h3>
                    <FontAwesomeIcon icon={faCreditCard}/> วิธีการชำระเงิน
                  </h3>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>ธนาคาร</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={this.state.bankAccount.bank_name || ''}
                          disabled
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <div className={classes.boxForm}>
                        <h4>เลขบัญชี</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={this.state.bankAccount.account_no || ''}
                          disabled
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.boxForm}>
                        <h4>ชื่อบัญชี</h4>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={this.state.bankAccount.account_name || ''}
                          disabled
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.boxLine}>
                        <div className={classes.boxContent}>
                          <Grid container spacing={0}>
                            <Grid item xs={12} sm={4} md={4}>
                              <div className={classes.lineQrcode}>
                                <img
                                  src={`${process.env.AWS_S3_URL}/assets/iTopPlus1291988635987.png`}
                                  alt=""
                                ></img>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8}>
                              <div className={classes.lineDescription}>
                                <h4>แจ้งการชำระเงิน</h4>
                                <p>
                                  ส่งหลักฐานการโอนเงินพร้อมแจ้งหมายเลขการจองผ่านทาง
                                  Line Official
                                </p>
                                <a
                                  href="http://line.me/ti/p/~@besthavenkhaoyai"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={`${process.env.AWS_S3_URL}/assets/iTopPlus41548004893.png`}
                                    alt=""
                                  ></img>
                                </a>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card className={classes.root}>
                <CardActionArea>
                  {houseImageHighLight && (
                    <CardMedia
                      className={classes.media}
                      image={houseImageHighLight.url}
                      title={house.name}
                    />
                  )}
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h6">
                      {house.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {(house.locations || []).map(element => {
                        return (
                          <Badge key={element.id} color="yellow">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                            {element.name}
                          </Badge>
                        )
                      })}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card className={classes.root} style={{ marginTop: "20px" }}>
                <CardActionArea>
                  <CardContent>
                    {/* <Typography color="textSecondary"> */}
                    <div className={classes.titleText}>
                      <div>
                        {moment(this.state.checkIn).format("DD MMM YYYY")} -{" "}
                        {moment(this.state.checkOut).format("DD MMM YYYY")}
                      </div>
                      <div>{checkOut.diff(checkIn, "days")} คืน</div>
                    </div>
                    {+this.state.adults > 0 && (
                      <div className={classes.subTitleText}>
                        <div className={classes.lightText}>- ผู้ใหญ่</div>
                        <div className={classes.lightText}>
                          {this.state.adults} คน
                        </div>
                      </div>
                    )}
                    {+this.state.adultsKid > 0 && (
                      <div className={classes.subTitleText}>
                        <div className={classes.lightText}>- เด็ก</div>
                        <div className={classes.lightText}>
                          {this.state.adultsKid} คน
                        </div>
                      </div>
                    )}
                    <div className={classes.subTitleText}>
                      <div>ผู้เข้าพักทั้งหมด</div>
                      <div>{+this.state.adults + +this.state.adultsKid} คน</div>
                    </div>

                    {/* </Typography> */}
                    <br />
                    <hr />
                    {/* <Typography variant="body2" color="textSecondary"> */}
                    <div className={classes.lightText}>
                      <FontAwesomeIcon icon={faUserFriends} /> ห้องพักสำหรับ{" "}
                      {house.max_person} คน{" "}
                    </div>
                    <div className={classes.lightText}>
                      <FontAwesomeIcon icon={faClock} /> เช็คอินตั่งแต่{" "}
                      {house.check_in
                        ? house.check_in.substring(0, house.check_in.length - 3)
                        : "14:00"}{" "}
                      น{" "}
                    </div>
                    <div className={classes.lightText}>
                      <FontAwesomeIcon icon={faClock} /> เช็คเอาท์ก่อน{" "}
                      {house.check_out
                        ? house.check_out.substring(
                          0,
                          house.check_out.length - 3
                        )
                        : "12:00"}{" "}
                      น{" "}
                    </div>
                    {/* </Typography> */}
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card className={classes.root} style={{ marginTop: "20px" }}>
                <CardActionArea>
                  <CardContent>
                    <div className={classes.subTitleText}>
                      <div>
                        ราคาที่จ่าย (x {checkOut.diff(checkIn, "days")} คืน)
                      </div>
                      <div>
                        ฿ {numberWithCommas((this.state.price || 0).toFixed(2))}
                      </div>
                    </div>
                    <div
                      className={classes.titleText}
                      style={{ color: "gray", fontSize: "14px" }}
                    >
                      <div>ภาษี ({this.state.vatRate || 0}%)</div>
                      <div>
                        ฿{" "}
                        {numberWithCommas(
                          (this.state.vatPrice || 0).toFixed(2)
                        )}
                      </div>
                    </div>
                    {/* <div
                      className={classes.titleText}
                      style={{ color: "gray", fontSize: "14px" }}
                    >
                      <div>ค่าประกัน </div>
                      <div>
                        ฿{" "}
                        {numberWithCommas(
                          (this.state.house.insurance_price || 0).toFixed(2)
                        )}
                      </div>
                    </div> */}
                    <div
                      className={classes.titleText}
                      style={{ color: "green", fontSize: "14px" }}
                    >
                      <div>ค่าธรรมเนียมการจอง</div>
                      <div>ฟรี</div>
                    </div>
                    <br />
                    <hr />
                    <div className={classes.titleText}>
                      <div>
                        <b>ทั้งหมด</b>
                      </div>
                      <div>
                        <b>
                          ฿{" "}
                          {/* {numberWithCommas(
                            (
                              this.state.totalPrice +
                              this.state.house.insurance_price
                            ).toFixed(2)
                          )} */}
                          {numberWithCommas(
                            (
                              this.state.totalPrice
                            ).toFixed(2)
                          )}
                        </b>
                      </div>
                    </div>

                    {this.state.payments && (
                      <div>
                        <div className={classes.subTitleText} style={{ color: 'green', fontSize: '14px' }}>
                          <div>จ่ายแล้ว</div>
                          <div>฿ {numberWithCommas(paymentTotal.toFixed(2))}</div>
                        </div>

                        <div
                          className={classes.titleText}
                          style={{ color: "red" }}
                        >
                          <div>
                            <b>ยอดค้างชำระรวม</b>
                          </div>
                          <div>
                            <b>
                              ฿{" "}
                              {/* {numberWithCommas(
                                (
                                  this.state.totalPrice +
                                  this.state.house.insurance_price -
                                  paymentTotal
                                ).toFixed(2)
                              )} */}
                              {numberWithCommas(
                                (
                                  this.state.totalPrice - paymentTotal
                                ).toFixed(2)
                              )}
                            </b>
                          </div>
                        </div>
                      </div>
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Layout>
    )
  }
}

const styles = theme => ({
  boxForm: {
    marginTop: "24px",
    fontFamily: `Kanit`,
    "& h4": {
      marginBottom: "10px",
      fontFamily: `Kanit`,
    },
    "& input": {
      fontFamily: `Kanit`,
      color: "#444444",
    },
  },
  bookingContainer: {
    "& span": {
      fontFamily: `Kanit`,
    },
  },
  bookingForm: {
    padding: "16px",
    "& h3": {
      marginTop: "30px",
      fontFamily: `Kanit`,
      color: "#444444",
    },
  },
  spaceBox: {
    height: "30px",
  },
  root: {
    // maxWidth: 345,
  },
  media: {
    fontFamily: `Kanit`,
    width: "100%",
    height: "200px",
  },
  titleText: {
    fontFamily: `Kanit`,
    display: "flex",
    justifyContent: "space-between",
    color: "#444444",
  },
  subTitleText: {
    fontFamily: `Kanit`,
    display: "flex",
    justifyContent: "space-between",
  },
  lightText: {
    fontFamily: `Kanit`,
    fontSize: "13px",
  },
  lineDescription: {
    textAlign: "left",
    "& img": {
      width: "230px",
      height: "auto !important",
      padding: "0px !important",
    },
    "& h4": {
      fontFamily: `Kanit`,
      marginTop: "10px",
      marginBottom: "10px",
    },
    "& p": {
      marginBottom: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      "& h4": {
        fontFamily: `Kanit`,
      },
    },
  },
  lineQrcode: {
    height: "170px",
  },
  boxContent: {
    textAlign: "center",
    "& img": {
      padding: "10px",
      maxHeight: "170px",
    },
    "& p": {
      fontSize: "17px",
      lineHeight: "28px",
      fontWeight: "400",
    },
    [theme.breakpoints.down("md")]: {
      "& p": {
        fontSize: "14px",
      },
    },
  },
  boxLine: {
    marginTop: "20px",
    backgroundColor: "#c7e9e2",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    padding: " 16px 16px",
  },
})

BookingDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}
export const page = inject("house", "booking")(observer(BookingDetail))
export default withStyles(styles, { withTheme: true })(page)
